import React from "react"
import { Link, graphql } from "gatsby"

// Utilities
import kebabCase from "lodash/kebabCase"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { rhythm, scale } from "../utils/typography"

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <div className="font-serif">
          <div className="px-6 md:px-20 md:mx-20 break-words">

            <p className="text-4xl font-bold text-primary pb-6">
              <a href="/blog">Blog</a>
            </p>

            <article>
              <header>
                <p>
                  <span className="text-3xl font-bold no-underline text-secondary">
                    {post.frontmatter.title}
                  </span>
                </p>
                <p className="pb-6">
                  {post.frontmatter.author &&
                    <span className="p-1 text-base text-gray-500" alt="updated">
                      {post.frontmatter.author}
                    </span>
                  }
                  <span className="p-4 text-base text-gray-500">
                    {post.frontmatter.date}
                  </span>
                  {post.frontmatter.modified &&
                    <span className="p-1 text-base text-gray-500" alt="updated">
                      (upd: {post.frontmatter.modified})
                    </span>
                  }
                </p>
              </header>
              <section className="markdown" dangerouslySetInnerHTML={{ __html: post.html }} />
            </article>

            {post.frontmatter.tags &&
              <div className="py-4 text-secondary">
                {post.frontmatter.tags.map((tag) => (
                  <Link
                    className="pr-4"
                    to={`/tags/${kebabCase(tag)}/`}>
                    {tag}
                  </Link>
                ))}
              </div>
            }

            <div className="py-4 flex justify-between">
                <div>
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev">
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </div>
                <div>
                  {next && (
                    <Link to={next.fields.slug} rel="next">
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </div>
            </div>


          </div>


        </div>


      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD.MM.YYYY - HH:mm")
        description
        author
        tags
        modified(formatString: "DD.MM.YYYY - HH:mm")
      }
    }
  }
`
